
import Layout from './components/layout'
import Navv from './components/nav'

function NavScrollExample() {
  return (
    <>
    <Navv />
    <br />
    <Layout />
   
    
    </>
  );
}

export default NavScrollExample;